(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("gsap"), require("sbInternalMsgBus"), require("ReactDOM"), require("sbRespBlockLib"), require("Draggable"), require("sbDataLayer"), require("mobxUtils"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-super-banner-block", ["React", "mobx", "mobxReact", "sbJsExtends", "gsap", "sbInternalMsgBus", "ReactDOM", "sbRespBlockLib", "Draggable", "sbDataLayer", "mobxUtils"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-super-banner-block"] = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("gsap"), require("sbInternalMsgBus"), require("ReactDOM"), require("sbRespBlockLib"), require("Draggable"), require("sbDataLayer"), require("mobxUtils"));
	else
		root["sb-responsive-super-banner-block"] = factory(root["React"], root["mobx"], root["mobxReact"], root["sbJsExtends"], root["gsap"], root["sbInternalMsgBus"], root["ReactDOM"], root["sbRespBlockLib"], root["Draggable"], root["sbDataLayer"], root["mobxUtils"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__107__, __WEBPACK_EXTERNAL_MODULE__146__) {
return 